#grid {
    display: grid;
    padding: 20px;
}

.grid-item {
    background-color: #fff;
    position: relative;
    overflow: hidden;
    width: 100%;
    /* The height will be determined by the padding-top set dynamically */
}

.grid-item img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
