.App {
}

.App-header {
    color: #282c34;
    min-height: 50px;
}

.App-link {
    color: #61dafb;
}

body {
    font-family: monospace;
    margin: 20px;
}

#outputControls {
    margin: 10px 0px;
}

.controls {
    display: flex;
    align-items: baseline;
    gap: 20px;
}

input[type='color'] {
    vertical-align: middle;
}
